/* ======================================================================== */
/* 41. SliderBackgrounds */
/* ======================================================================== */
var SliderBackgrounds = function ($backgrounds) {

	var $sliders = $('.section-headings-slider');

	$document
		.on('mouseenter touchstart', '.slider a[data-slide-id]', function () {
			var
				postId = $(this).data('slide-id'),
				$targetBackground = $backgrounds.filter('[data-background-for="' + postId + '"]');

			if (!$targetBackground.length) {
				return;
			}

			$targetBackground.addClass('active');
			window.$header.removeClass('header_black').addClass('header_white');
			$sliders.addClass('color-white');

		})
		.on('mouseleave touchend', '.slider a[data-slide-id]', function () {

			$backgrounds.filter('.active').removeClass('active');
			window.$header.removeClass('header_white').addClass('header_black');
			$sliders.removeClass('color-white');

		})
		.on('click', '.slider a[data-slide-id]', function () {

			var
				postId = $(this).data('slide-id'),
				$targetBackground = $backgrounds.filter('[data-background-for="' + postId + '"]'),
				$targetBackgroundGlobal = window.$backgrounds.filter('[data-background-for="' + postId + '"]');

			if (!$targetBackground.length) {
				return;
			}

			$backgrounds.filter('active').removeClass('active');
			$targetBackgroundGlobal.addClass('selected');
			$targetBackground.addClass('selected');

		});

}
