/* ======================================================================== */
/* 52. hideLines */
/* ======================================================================== */
function hideLines($target, duration = 0.6, stagger = 0.02, offset = '-100%', reverse) {

	var
		tl = new TimelineMax(),
		$words = $target.find('.split-text__word');

	if (reverse) {
		$words = $words.get().reverse();
	}

	if ($words.length) {

		tl.staggerTo($words, duration, {
			y: offset
		}, stagger);

	};

	return tl;

}
