/* ======================================================================== */
/* 57. fixMobileBarHeight */
/* ======================================================================== */
function fixMobileBarHeight() {

	var vh;

	/**
	 * Initial set
	 */
	createStyleElement();
	setVh();

	/**
	 * Resize handling (with debounce)
	 */
	$(window).on('resize', debounce(function () {
		setVh();
	}, 250));

	/**
	 * 100vh elements height correction
	 */
	function setVh() {

		vh = window.innerHeight * 0.01;

		$('#rubenz-fix-bar').html(':root { --fix-bar-vh: ' + vh + 'px; }\n');

	}

	function createStyleElement() {

		if (!$('#rubenz-fix-bar').length) {
			$('head').append('<style id=\"rubenz-fix-bar\"></style>');
		}

	}

}
