/* ======================================================================== */
/* 51. doSplitText */
/* ======================================================================== */
function doSplitText($target) {

	var
		$words = $($target).find('.split-text'),
		$chars = $($target).find('.split-chars');

	return new Promise(function (resolve, reject) {

		if ($words.length) {

			TweenMax.set($words, {
				autoAlpha: 1,
			});

			new SplitText($words, {
				type: 'words, lines',
				linesClass: 'split-text__line',
				wordsClass: 'split-text__word',
			});

		}

		if ($chars.length) {

			TweenMax.set($chars, {
				autoAlpha: 1,
			});

			new SplitText($chars, {
				type: 'words, chars',
				// linesClass: 'split-chars__line',
				wordsClass: 'split-text__word',
				charsClass: 'split-chars__char'
			});


		}

		resolve(true);

	});

}
